<template>
  <v-main>
    <v-container
      class="fill-height pt-12 pb-12"
      fluid
    >
      <router-view></router-view>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>

<style scoped>

</style>
